<template>
  <v-btn 
    depressed 
    color="azul2"
    class="white--text"
    light
    min-width="150px">
    <v-img 
      :class="
      [
        'mr-2', 
        'pl-0',
        isOpened ? 'rotate-arrow' : ''
      ]"
       max-width="12px" src="assets/ic-arrow-bottom.svg" />
    {{ isOpened ? 'OCULTAR DETALLES' : 'VER DETALLES' }}
    </v-btn>
</template>

<script>
export default {
  name: 'VerDetallesButton',
  props: {
    isOpened: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style>
.rotate-arrow {
  transform: rotate(180deg);
}
</style>